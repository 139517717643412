<template>
  <div class="costing">
    <div>
      <van-row>
        <van-col span="16">
          <van-search left-icon="" v-model="saveValue" placeholder="本次待保存结果名称"/>
        </van-col>
        <van-col span="8">
          <van-button type="primary" size="mini" class="button" @click="saveCosting">保存结果</van-button>
          <van-button type="info" size="mini" class="button" @click="toContrast">对比结果</van-button>
        </van-col>
      </van-row>
    </div>
    <div class="title">
      <van-row>
        <van-col offset="1" span="8">
          <div style="border-left: #2CD79F solid 2px;">
            老船总成本
          </div>
        </van-col>
        <van-col offset="3" span="8">
          <div style="border-left: #2CD79F solid 2px;">
            新船总成本
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="title-text">
      <van-row>
        <van-col offset="1" span="10">
          <div class="title-box">
            <van-row>
              <van-col offset="1" span="11">
                航次成本 >
              </van-col>
              <van-col span="12">
                <span class="title-num">{{ voyageCost | ellipsis }}</span>
              </van-col>
            </van-row>
            <van-row>
              <van-col offset="1" span="11">
                运营成本 >
              </van-col>
              <van-col span="12">
                <span class="title-num">{{ operateCost | ellipsis }}</span>
              </van-col>
            </van-row>
          </div>
        </van-col>
        <van-col offset="1" span="10">
          <div class="title-box">
            <van-row>
              <van-col offset="1" span="11">
                航次成本 >
              </van-col>
              <van-col span="12">
                <span class="title-num">{{ voyageCost1 | ellipsis }}</span>
              </van-col>
            </van-row>
            <van-row>
              <van-col offset="1" span="11">
                运营成本 >
              </van-col>
              <van-col span="12">
                <span class="title-num">{{ operateCost1 | ellipsis }}</span>
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="box">
      <van-row>
        <van-col offset="1" span="3">
          <div class="uncommon-parameter">
          </div>
        </van-col>
        <van-col offset="0" span="8">
          <div class="parameter-text">
            老船参数
          </div>
        </van-col>
        <van-col offset="0" span="3">
          <div class="uncommon-parameter">
          </div>
        </van-col>
        <van-col offset="0" span="8">
          <div class="parameter-text">
            新船参数
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="table-value">
      <table class="table">
        <th class="th-tr" v-for="item in th">
          <tr>{{ item.name }}</tr>
        </th>
        <tr v-for="item in table[0].tr" class="td-tr"
            :style="{backgroundColor:  item.public ? '#F0F9EB' :  item.use ? '#FDF5E6' : '#FEFDFF'}">
          <td>
            <van-row>
              <van-col span="10" style="margin-top: 14px">
                {{ item.name }}
              </van-col>
              <van-col span="14">
                <van-field v-model="item.value" :readonly="!item.use" :type="item.name !='航线' ? 'number' : 'text'"
                           placeholder="请输入">
                  <template #right-icon>
                    <van-icon class-prefix="icon" v-if="item.name =='剩余年回报率'" name="24gl-percent" color="#000000"
                              size="12"/>
                  </template>
                </van-field>

              </van-col>
            </van-row>
          </td>
          <td v-if="item.name1.length >0">
            <van-row>
              <van-col span="10" style="margin-top: 14px">
                {{ item.name1 }}
              </van-col>
              <van-col span="14">
                <van-field v-model="item.value1" :readonly="!item.use" :type="item.name1 !='货种' ? 'number' : 'text'"
                           placeholder="请输入">
                  <template #right-icon>
                    <van-icon class-prefix="icon" v-if="item.name =='剩余年回报率'" name="24gl-percent" color="#000000"
                              size="12"/>
                  </template>
                </van-field>
              </van-col>
            </van-row>
          </td>
        </tr>
        <th class="th-tr" v-for="item in th1">
          <tr>{{ item.name }}</tr>
        </th>
        <tr v-for="item in table[0].tr1" class="td-tr"
            :style="{backgroundColor:  item.public ? '#F0F9EB' :  item.use ? '#FDF5E6' : '#FEFDFF'}">
          <td>
            <van-row>
              <van-col span="10" style="margin-top: 14px">
                {{ item.name }}
              </van-col>
              <van-col span="14">
                <van-field v-model="item.value" :readonly="!item.use" :type="item.name !='航线' ? 'number' : 'text'"
                           placeholder="请输入">
                  <template #right-icon>
                    <van-icon class-prefix="icon" v-if="item.name =='剩余年回报率'" name="24gl-percent" color="#000000"
                              size="12"/>
                  </template>
                </van-field>

              </van-col>
            </van-row>
          </td>
          <td v-if="item.name1.length >0">
            <van-row>
              <van-col span="10" style="margin-top: 14px">
                {{ item.name1 }}
              </van-col>
              <van-col span="14">
                <van-field v-model="item.value1" :readonly="!item.use" :type="item.name1 !='货种' ? 'number' : 'text'"
                           placeholder="请输入">
                  <template #right-icon>
                    <van-icon class-prefix="icon" v-if="item.name =='剩余年回报率'" name="24gl-percent" color="#000000"
                              size="12"/>
                  </template>
                </van-field>
              </van-col>
            </van-row>
          </td>
        </tr>
      </table>
    </div>
    <div class="table-common">
      <table class="table">
        <tr v-for="item in table1" class="td-tr"
            :style="{backgroundColor:  item.public ? '#F0F9EB' :  item.use ? '#FDF5E6' : '#FEFDFF'}">
          <td>
            <van-row>
              <van-col span="10" style="margin-top: 14px">
                {{ item.name }}
              </van-col>
              <van-col span="14">
                <van-field v-model="item.value" :readonly="!item.use" type="number" placeholder="请输入"/>
              </van-col>
            </van-row>
          </td>
          <td>
            <van-row>
              <van-col span="10" style="margin-top: 14px">
                {{ item.name1 }}
              </van-col>
              <van-col span="14">
                <van-field v-model="item.value1" :readonly="!item.use" type="number"
                           placeholder="请输入"/>
              </van-col>
            </van-row>
          </td>

        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import {Toast} from "vant";
import {saveCosting, saveCosting1, getDisCosting} from '@/api/costing'

export default {
  name: "Costing",
  filters: {
    // 过滤器filter,不允许超过10个长度
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 7) {
        return value.slice(0, 7) + '...'
      }
      return value
    }
  },
  watch: {
    table: {
      //深度监视
      deep: true,
      //固定方法，handler,当被监视的属性发生变化时，调用该方法
      //一个参数 为变化后的值，第二个参数 为变化前的值
      handler(newValue, oldValue) {
        console.log(oldValue);
        //新老船总造价
        this.table1[2].value = newValue[0].tr[0].value * newValue[0].tr[1].value
        this.table1[2].value1 = newValue[0].tr[0].value1 * newValue[0].tr[1].value1
        //新船造价成本
        this.table1[3].value1 = newValue[0].tr[0].value1 * newValue[0].tr[1].value1
        //老船剩余造价成本
        this.table1[3].value = newValue[0].tr[0].value * newValue[0].tr[1].value - newValue[0].tr[10].value

        //老船航行单天（）
        this.table1[4].value = Math.round(
            ((this.table1[3].value * newValue[0].tr[2].value * 0.01 + newValue[0].tr[3].value * 12) / 365 +
                newValue[0].tr[4].value * newValue[0].tr[6].value + newValue[0].tr[5].value * newValue[0].tr[7].value) / newValue[0].tr[0].value
            * 100) / 100
        //新船航行单天（）
        this.table1[4].value1 = Math.round(
            ((this.table1[3].value1 * newValue[0].tr[2].value1 * 0.01 + newValue[0].tr[3].value1 * 12) / 365 +
                newValue[0].tr[4].value1 * newValue[0].tr[6].value1 + newValue[0].tr[5].value1 * newValue[0].tr[7].value1) / newValue[0].tr[0].value1
            * 100) / 100
        //老船抛锚单天（）
        this.table1[5].value = Math.round(
            ((this.table1[3].value * newValue[0].tr[2].value * 0.01 + newValue[0].tr[3].value * 12) / 365 +
                newValue[0].tr[5].value * newValue[0].tr[7].value) / newValue[0].tr[0].value
            * 100) / 100
        //新船抛锚单天（）
        this.table1[5].value1 = Math.round(
            ((this.table1[3].value1 * newValue[0].tr[2].value1 * 0.01 + newValue[0].tr[3].value1 * 12) / 365 +
                newValue[0].tr[5].value1 * newValue[0].tr[7].value1) / newValue[0].tr[0].value1
            * 100) / 100

        //老船船型
        this.table1[0].value = newValue[0].tr[0].value
        //新船船型
        this.table1[0].value1 = newValue[0].tr[0].value1
        //航行天数
        this.table1[1].value = Math.round(newValue[0].tr1[1].value / newValue[0].tr[8].value / 24 * 100) / 100
        this.table1[1].value1 = Math.round(newValue[0].tr1[1].value / newValue[0].tr[8].value1 / 24 * 100) / 100
        this.voyageCost = this.calulateCostingVovage(Number(this.table1[1].value), Number(this.table1[4].value), Number(newValue[0].tr1[1].value1),
            Number(this.table1[5].value), Number(newValue[0].tr[9].value))
        this.voyageCost1 = this.calulateCostingVovage(Number(this.table1[1].value1), Number(this.table1[4].value1), Number(newValue[0].tr1[1].value1),
            Number(this.table1[5].value1), Number(newValue[0].tr[9].value1))
        this.operateCost = this.calulateCostingOperate(Number(newValue[0].tr[4].value), Number(newValue[0].tr[6].value), Number(newValue[0].tr[5].value),
            Number(newValue[0].tr[7].value), Number(newValue[0].tr[3].value), Number(this.table1[0].value), Number(this.table1[1].value), Number(newValue[0].tr[9].value),
            Number(newValue[0].tr1[1].value1))
        this.operateCost1 = this.calulateCostingOperate(Number(newValue[0].tr[4].value1), Number(newValue[0].tr[6].value1), Number(newValue[0].tr[5].value1),
            Number(newValue[0].tr[7].value1), Number(newValue[0].tr[3].value1), Number(this.table1[0].value1), Number(this.table1[1].value1), Number(newValue[0].tr[9].value1),
            Number(newValue[0].tr1[1].value1))
      }
    },
  },
  data() {
    return {
      saveValue: '',
      voyageCost: '0',
      voyageCost1: '0',
      operateCost: '0',
      operateCost1: '0',
      th: [
        {
          name: '基础参数',
        },
      ],
      th1: [
        {
          name: '航次参数',
        },
      ],
      table: [
        {
          name: '老船参数',
          tr: [
            {
              name: '老船吨位',
              value: '21000',
              use: true,
              name1: '新船吨位',
              value1: '17000',
              public: false,
            },
            {
              name: '老船造价（单吨）',
              value: '3300',
              use: true,
              name1: '新船造价（单吨）',
              value1: '3000',
              public: false,
            },
            {
              name: '剩余年回报率',
              value: '12.5%',
              use: true,
              name1: '剩余年回报率',
              value1: '12.5%',
              public: false,
            },
            {
              name: '月管理费用',
              value: '480000',
              use: true,
              name1: '月管理费用',
              value1: '450000',
              public: false,
            },
            {
              name: '重油耗',
              value: '10',
              use: true,
              name1: '重油耗',
              value1: '6',
              public: false,
            },
            {
              name: '轻油耗',
              value: '0.5',
              use: true,
              name1: '轻油耗',
              value1: '0.5',
              public: false,
            },
            {
              name: '重油价',
              value: '5500',
              use: true,
              name1: '重油价',
              value1: '5500',
              public: false,
            },
            {
              name: '轻油价',
              value: '7000',
              use: true,
              name1: '轻油价',
              value1: '7000',
              public: false,
            },
            {
              name: '航速',
              value: '11',
              use: true,
              name1: '航速',
              value1: '10',
              public: false,
            },
            {
              name: '装卸天数',
              value: '6',
              use: true,
              name1: '装卸天数',
              value1: '5.5',
              public: false,
            },
            {
              name: '已收益',
              value: '10000000',
              use: true,
              name1: '',
              value1: '',
              public: false,
            },
          ],
          tr1: [
            {
              name: '航线',
              value: '鞍钢上海',
              use: true,
              name1: '货种',
              value1: '钢材',
              public: true,
            },
            {
              name: '海里数',
              value: '1500',
              use: true,
              name1: '两港单吨费用',
              value1: '2.3',
              public: true,
            },
          ]
        },
      ],
      table1: [
        {
          name: '船型',
          value: '21000',
          use: false,
          name1: '船型',
          value1: '17000',
          public: false,
        },
        {
          name: '航行天数',
          value: '5.68',
          use: false,
          name1: '航行天数',
          value1: '6.25',
          public: false,
        },
        {
          name: '老船总造价',
          value: '69300000',
          use: false,
          name1: '新船总造价',
          value1: '51000000',
          public: false,
        },
        {
          name: '剩余造价成本',
          value: '59300000',
          use: false,
          name1: '造价成本',
          value1: '51000000',
          public: false,
        },
        {
          name: '航行单天',
          value: '4.50',
          use: false,
          name1: '航行单天',
          value1: '4.04',
          public: false,
        },
        {
          name: '抛锚单天',
          value: '1.89',
          use: false,
          name1: '抛锚单天',
          value1: '2.10',
          public: false,
        },

      ]
    }
  },
  methods: {
    //航行天数*航行单天 + 两港单吨费用 + 抛锚单天*装卸天数
    calulateCostingVovage(hxts, hxdt, lgddfy, pmdt, zxts) {
      return ((hxts * hxdt) + lgddfy + (pmdt * zxts)).toFixed(2)
    },
    //(重油耗*重油价 + 轻油耗*轻油价 + 月管理费用 * 12 /365 )/船型*航行天数 + (轻油耗*轻油价 + 月管理费用 * 12 /365)/船型*装卸天数 + 两港单吨费用
    calulateCostingOperate(zyh, zyj, qyh, qyj, yglfy, cx, hxts, zxts, lgddfy) {
      return (((zyh * zyj) + (qyh * qyj) + yglfy * 12 / 365) / cx * hxts + (qyh * qyj + yglfy * 12 / 365) / cx * zxts + lgddfy).toFixed(2)
    },
    saveCosting() {
      if (!this.saveValue.length > 0) {
        Toast.fail("请输入保存名称！")
      } else {
        getDisCosting({
          buscomparisonName: this.saveValue,
        }).then(res => {
          if (res.length > 0) {
            Toast.fail('名称重复,请更换！')
          } else {
            saveCosting({
              buscomparisonName: this.saveValue,
              dwt: Number(this.table[0].tr[0].value).toFixed(2),
              costTon: Number(this.table[0].tr[1].value).toFixed(2),
              costAll: Number(this.table1[2].value).toFixed(2),
              costResidue: Number(this.table1[3].value).toFixed(2),
              costRecover: Number(this.table[0].tr[10].value).toFixed(2),
              returnYy: this.table[0].tr[2].value,
              costManage: Number(this.table[0].tr[3].value).toFixed(2),
              heavyOil: Number(this.table[0].tr[4].value).toFixed(2),
              lightOil: Number(this.table[0].tr[5].value).toFixed(2),
              costHeavy: Number(this.table[0].tr[6].value).toFixed(2),
              costLight: Number(this.table[0].tr[7].value).toFixed(2),
              costSail: Number(this.table1[4].value).toFixed(2),
              costDrop: Number(this.table1[5].value).toFixed(2),
              routeName: this.table[0].tr1[0].value,
              goodsType: this.table[0].tr1[0].value1,
              nauticalMiles: Number(this.table[0].tr1[1].value).toFixed(2),
              costPorts: Number(this.table[0].tr1[1].value1).toFixed(2),
              speed: Number(this.table[0].tr[8].value).toFixed(2),
              layDay: Number(this.table[0].tr[9].value).toFixed(2),
              voyageDays: Number(this.table1[1].value).toFixed(2),
              costVoyage: Number(this.voyageCost).toFixed(2),
              costBus: Number(this.operateCost).toFixed(2),
              crePers: this.$store.getters.userId,
            }).then(res => {
              console.log(res)
              saveCosting1({
                buscomparisonName: this.saveValue,
                dwt: Number(this.table[0].tr[0].value1).toFixed(2),
                costTon: Number(this.table[0].tr[1].value1).toFixed(2),
                costAll: Number(this.table1[2].value1).toFixed(2),
                costResidue: Number(this.table1[3].value1).toFixed(2),
                costRecover: 0,
                returnYy: this.table[0].tr[2].value1,
                costManage: Number(this.table[0].tr[3].value1).toFixed(2),
                heavyOil: Number(this.table[0].tr[4].value1).toFixed(2),
                lightOil: Number(this.table[0].tr[5].value1).toFixed(2),
                costHeavy: Number(this.table[0].tr[6].value1).toFixed(2),
                costLight: Number(this.table[0].tr[7].value1).toFixed(2),
                costSail: Number(this.table1[4].value1).toFixed(2),
                costDrop: Number(this.table1[5].value1).toFixed(2),
                routeName: this.table[0].tr1[0].value,
                goodsType: this.table[0].tr1[0].value1,
                nauticalMiles: Number(this.table[0].tr1[1].value).toFixed(2),
                costPorts: Number(this.table[0].tr1[1].value1).toFixed(2),
                speed: Number(this.table[0].tr[8].value1).toFixed(2),
                layDay: Number(this.table[0].tr[9].value1).toFixed(2),
                voyageDays: Number(this.table1[1].value1).toFixed(2),
                costVoyage: Number(this.voyageCost1).toFixed(2),
                costBus: Number(this.operateCost1).toFixed(2),
                crePers: this.$store.getters.userId,
              }).then(res => {
                console.log(res)
                this.saveValue = ''
                Toast.success('添加成功！');
              }).catch(err => {
                console.log(err)
                Toast.fail('添加失败！');
              })
            }).catch(err => {
              console.log(err)
              Toast.fail('添加失败！');
            })
          }
        }).catch(err => {
          console.log(err)
          Toast.fail('添加失败！');
        })
      }
    },
    toContrast() {
      this.$router.push({path: "/CostingContrast"});
    }
  },

}
</script>

<style scoped>

.costing {
  background: #FEFDFF;
  /*height: 1300px*/
}

.costing .button {
  margin-top: 15px;
}

.costing .title {
  background: #FEFDFF;
  font-size: 16px;
  margin-top: 10px;
}

.costing .title-text {
  margin-top: 10px;
  color: #9A98A2;
}

.costing .title-text .title-box {
  background: #F9F8FB;
  border: #FEFDFF solid 1px;
  height: 70px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

.costing .title-text .title-box .title-num {
  font-weight: bolder;
  color: black;
  font-size: 20px;
}

.table-value {
  height: 250px;
  overflow-y: scroll;
  margin-top: 10px;
}

.table {
  width: 95%;
  margin: 0 auto;
  padding: 1px;
  border: 1px solid #ccc;
  cellspacing: 0;
  cellpadding: 0;
  margin-top: 20px;
  /*text-align: center*/
}

.td-tr {
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}

.costing .uncommon-parameter {
  background: oldlace;
  width: 35px;
  height: 20px;
  border-radius: 5px;
  margin-top: 10px;
}

.costing .common-parameter {
  background: #F0F9EB;
  width: 35px;
  height: 20px;
  border-radius: 5px;
  margin-top: 10px;
}

.costing .parameter-text {
  text-align: left;
  margin-left: 2%;
  margin-top: 10px;
  font-size: 14px;
}

.costing .parameter-text {
  text-align: left;
  margin-left: 2%;
  margin-top: 10px;
  font-size: 14px;
}

</style>